<template>
  <div class="taskListListView">
    <van-sticky>
      <van-nav-bar left-arrow @click-left="onClickLeft" :title="$t('任务列表')" />
    </van-sticky>
    <van-list
      v-model="loading"
      :finished="finished"
      loading-text="..."
      :finished-text="$t('没有更多了')"
      @load="onLoad"
    >
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
        <div class="left">
          
          <a href="javascript:;">
            <img :src="$imageUrl + item.thumb" />
          </a>
          <h4>{{ item.type_name }}</h4>
          <van-button type="info" size="mini">{{ item.level_name }}</van-button>
        </div>
        <div class="center">
          <span>
            {{ $t('剩余') }}:
            <strong>{{ item.surplus }}</strong>
          </span>
          <span class="price">円:<strong>{{ item.price }}</strong>
          </span>
          <span class="bot">{{ $t('要求') }}:{{ $t('观看视频') }} <van-button
              type="info"
              size="mini"
              @click.stop="onReceive(item.id)"
              :disabled="item.disabled"
            >{{ $t('领取') }}</van-button></span>
        </div>

      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getTaskList, getTask } from "@/api/task";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    //领取任务
    onReceive(id) {
      getTask({ id: id })
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 1000,
            position: "bottom"
          });
          this.list.forEach((val, key) => {
            if (val.id == id) {
              this.list[key].disabled = true;
              return false;
            }
          });
        })
        .catch(error => {});
    },
    //获取任务列表
    GetTaskList() {
      getTaskList({
        type: this.$route.query.type,
        level: this.$route.query.level,
        lang: this.$lang,
        page: this.page,
        pageSize: this.pageSize
      })
        .then(response => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    onLoad() {
      this.GetTaskList();
    }
  }
};
</script>
<style lang="stylus">
.taskListListView
  .van-list
    display: flex;
    flex-wrap: wrap;
    .xiax_item
      display: flex
      margin-bottom: 0.05rem
      flex-basis: 50%;
      margin-right: 0.02rem;
      flex-basis: calc(50% - 0.02rem);
      position: relative;
      box-sizing: border-box;
      .van-button--disabled
        background: #888 !important
        border-color: #888 !important
      .van-cell__value
        display: flex
        color: #bbb

        flex-direction: column;
        .left
          text-align: center
          h4
            margin: 0;
            font-weight: 400;
            color: #fff;
            font-size: 0.32rem;
            line-height: 0.32rem;
          a
            display: block
            width: 100%
            height: 3rem
            overflow: hidden
            margin: 0 auto 0.2rem;
            img
              display: block
              width: 100%
              height: 100%
              object-fit: contain
          .van-button--mini
            padding: 0 0.1rem
        .center
          flex: 1
          font-size: 0.28rem
          padding: 6px
          line-height: 0.55rem
          text-align: center;
          span
            display: inline-block;
            strong
              color: $specColor
          .price
            margin-left: 0.1rem;
            font-weight: 500
            font-size: 0.3rem
            strong
              color: $specColor
              font-size: 0.3rem      
          .bot
              display: flex;
              align-items: center; 
              justify-content: center;
            .van-button--mini
              margin-left: 0.1rem;
              height: 0.5rem;      
   
    .van-cell
      background-color: $cellBackColor
</style>
