import request from '@/utils/request';
import Vue from 'vue';

//获取任务列表
export function getTaskList(data) {
  return request({
    url: '/task/getTaskList?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//领取任务
export function getTask(data) {
  return request({
    url: '/task/getTask?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
//任务详情
export function getTaskDetail(data) {
  return request({
    url: '/task/getTaskDetail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//任务订单
export function getOrderLists(data) {
  return request({
    url: '/task/getOrderLists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//提交任务
export function orderSubmit(data) {
  return request({
    url: '/task/orderSubmit?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

//任务订单详情
export function getTaskOrderDetail(data) {
  return request({
    url: '/task/getTaskOrderDetail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//放弃任务
export function cancelTask(data) {
  return request({
    url: '/task/cancelTask?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取七牛token
export function getUploadToken(data) {
  return request({
    url: '/task/getUploadToken?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取日结报表
export function getDayReport(data) {
  return request({
    url: '/task/getDayReport?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//获取团队报表
export function getTeamReport(data) {
  return request({
    url: '/task/getTeamReport?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//获取团队信息
export function getTeamInfo(data) {
  return request({
    url: '/task/getTeamInfo?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//获取团队列表
export function getTeamList(data) {
  return request({
    url: '/task/getTeamList?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
